import React, { Fragment, useContext } from "react";
import { graphql, PageProps } from "gatsby"
import Container from '@mui/material/Container';
import { useBreakpoint } from "../components/_shared/contextBreakpointMediaQ";
import useTheme from "@mui/system/useTheme";
import { MDXRenderer } from "gatsby-plugin-mdx"

import { ContextGdprCookieConsentValue } from "../components/_shared/ContextGdprCookieConsent";
import CookieGdprConsentPopup from "../components/_shared/CookieGdprConsentPopup"
import Seo from "../components/_shared/_seo";
import LayoutLMR from "../components/_shared/_layoutLMR";
import { COMPONENTS_FILENAMES_IN_TEMPLATE as comps } from "../components/_shared/mapComponents"
import LazyDynamicComponent from "../components/_shared/LazyDynamicComponent";

const styleBase = {
  display: "flex",
  flexDirection: "column",
};

export const pageQuery = graphql`query ($id: String!){
  pageContentQueried: mdx (
      id: {eq: $id}
  ) {
        id
        body
        frontmatter {
          title
          sectionLMR
          sections
          shouldRenderMdx
          pageHeader {
            tag
            title
          }
          seo {
            description
            keywords
            lang
            title
          }
          structuredData
        }
  }
}
`;


const heightHeader = 80

const styleSxWrap = {
  display: "flex",
  flexDirection: "column",
  // minHeight: "100vh",
  overflowX: "hidden",
  overflowY: 'hidden',
  position: "relative",
  px: [ "1px" ],
  "& .templContHeader": {
    display: "flex",
    alignItems: "center",
  },
  "& .templContFullUp": {
    px: 0,
    position: "relative",
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    "& .templFullUp": {
      display: "flex",
      flexDirection: "column",
      width: 'inherit',
      background: (theme) => theme.gradients.lin3(0, theme.palette.primary2.main, "0%", theme.palette.primary2.light, "50%", theme.palette.primary2.main, "100%",),
    },
  },
  "& #mainUp": {
    position: "relative",
    // px: [ 0, '0.5rem', '2rem' ],
    px: [ 0 ],
    // overflow: 'hidden',
  },
  "& .templContFoot": {
    position: "relative",
    color: "primary2.contrastText",
    background: (theme) => theme.gradients.lin3(0, theme.palette.primary2.main, "0%", theme.palette.primary2.light, "50%", theme.palette.primary2.main, "100%",),
    my: 0,
  },
  "& .fullD": {
    background: (theme) => theme.gradients.lin3(0, theme.palette.primary2.main, "0%", theme.palette.primary2.light, "50%", theme.palette.primary2.main, "100%",),
  }
}


// : PageProps<Queries.TypegenPageQuery>

/** PageTempl
 * getting data from pageQuery above
 * page template with very flexible layout
 * lot of components called conditionally - if file exists in /components/pagesContents/${contentDir}/
 * these files should be named as mapped in COMPONENTS_FILENAMES_IN_TEMPLATE
 * Page wrapper under Layout wrapper
 * @param  {} props
 */
const PageTempl = (props) => {

  const {
    data,
    path,
    pageContext,
  } = props;

  const theme = useTheme();
  const deviceSize = useBreakpoint()
  const isDesktop = [ "xs", "sm", "md" ].includes(deviceSize)
  const isMobileSm = [ "xs", "sm" ].includes(deviceSize)

  const {
    contentDir = "notFound",
    nameCookieGdpr,
  } = pageContext;

  const { pageContentQueried } = data

  const { frontmatter } = pageContentQueried

  const mdxBody = pageContentQueried.body;
  const {
    sections,
    shouldRenderMdx,
    seo,
    structuredData,
    title,
  } = frontmatter;

  const isMobileUp = sections?.includes("MobileUp")
  const isMobileDown = sections?.includes("MobileDown")
  // const isFullUp = sections?.includes("FullUp")
  const isFullDown = sections?.includes("FullDown")
  const isMainDown = sections?.includes("MainDown")

  const isMobileUp2 = sections?.includes("MobileUp2")
  const isMobileDown2 = sections?.includes("MobileDown2")
  const isFullDown2 = sections?.includes("FullDown2")

  const isSpecialAbsLayer = sections?.includes("SpecialAbsLayer") && !isMobileSm
  const structuredDataFullFromFile = structuredData ? require(`../components/pagesContents/${contentDir}/_structuredData.json`) : undefined

  const { isCookieSet } = useContext(ContextGdprCookieConsentValue)

  const optionsColorsSeparatorAbs = {
    gradientBgColor: theme.palette.secondary2.main,
    fillColors: [ theme.palette.primary2.main, theme.palette.primary2.dark, theme.palette.primary2.main ],
    strokeColor: theme.palette.primary2.contrastText,
  }

  {/* <Suspense fallback="...loading">
    <LazyDarkModeToggleButton />
    <LazyHeader heightHeader={heightHeader} leftShift={widthColorButton} contentDir={contentDir} isDesktop={isDesktop} />
  </Suspense> */}
  return (
    <Fragment>
      {isSpecialAbsLayer && (
        <section style={styleBase} id="SpecialAbsLayer" key="SpecialAbsLayer">
          <LazyDynamicComponent chunkName={`${contentDir}-SpecialAbsLayer`} relPath={`${contentDir}/${comps.SpecialAbsLayer}`} isQuick={true} />
        </section>
      )}
      {!isCookieSet && <CookieGdprConsentPopup heightHeader={heightHeader} nameCookieGdpr={nameCookieGdpr} key="cook" />}
      <Seo
        path={path}
        seo={seo}
        structuredDataFull={structuredDataFullFromFile}
        key="seo"
      />
      {/* {isFullUp && (
        <Container
          maxWidth={false}
          className="templContFullUp"
          key="cont-fullUp"
        >
          <section
            className="templFullUp"
            style={styleBase}
            id="fullUp"
            key="fullUp"
          >
            <LazyDynamicComponent compProps={{ title, breakpoint: deviceSize }} chunkName={`${contentDir}-FullUp`} relPath={`${contentDir}/${comps.FullUp}`} isQuick={true} />

          </section>
        </Container>
      )} */}
      <Container id="templ"
        maxWidth={false}
        component="div"
        sx={styleSxWrap}
      >
        {/* <Container
          maxWidth={false}
          className="templContHeader"
          key="cont-header"
          style={{
            height: `${heightHeader}px`,
          }}
        >
          <DarkModeToggleButton />
          <Header heightHeader={heightHeader} leftShift={widthColorButton} isDesktop={isDesktop} />
        </Container> */}

        {/* <FrameMaskForDiv
          options={{
            frames: [ "right", "bottom", "left" ],
            masks: { top: "mask11", right: "mask11V", bottom: "mask11", left: "mask11V" },
            rightFr: { isCornerMask: false }, bottomFr: { isCornerMask: false }, leftFr: { isCornerMask: false }
          }}
        >
          <FrameBgForDiv options={{
            frames: [
              "right",
              "bottom",
              "left" ],
            bgImages: {
              right: "bgI7VR",
              bottom: "bgI7",
              left: "bgI7VL"
            },
            styleAll: {
              backgroundSize: '100% auto'
            },
            topFr: {
              isCornerBg: false,
              frameThickness: 100,
              xDiff: 10,
              yDiff: 10,
              style: {
                backgroundSize: 'contain'
              }
            },
            rightFr: {
              isCornerBg: false,
              frameThickness: 100,
              xDiff: 0,
              yDiff: 0,
            },
            bottomFr: {
              isCornerBg: false, frameThickness: 100,
              xDiff: 0,
              yDiff: 0,
              style: {
                backgroundSize: 'contain'
              }
            },
            leftFr: {
              isCornerBg: false, frameThickness: 100,
              xDiff: 0,
              yDiff: 0,
            }
          }}
          > */}

        {isMobileUp && (
          <section style={styleBase} id="mobileUpI" key="mobileUp">
            <LazyDynamicComponent chunkName={`${contentDir}-MobileUp`} relPath={`${contentDir}/${comps.MobileUp}`} isQuick={true} />

          </section>
        )}

        {isMobileUp2 && (
          <section style={styleBase} id="mobileUp" key="mobileUp2">
            <LazyDynamicComponent chunkName={`${contentDir}-MobileUp2`} relPath={`${contentDir}/${comps.MobileUp2}`} isQuick={true} />
          </section>
        )}

        <Container component={"section"}
          maxWidth={false} // "xl"
          style={{
            ...styleBase,
          }}
          id="mainUp"
          key={`${contentDir}-templMainUp`}
        >
          <LazyDynamicComponent compProps={{ title, isDesktop }} chunkName={`${contentDir}-MainUp`} relPath={`${contentDir}/${comps.MainUp}`} isQuick={true} />
        </Container>

        {(shouldRenderMdx) &&
          (<Container component={"section"} maxWidth="xl" style={styleBase} id="layoutLMR" key="layoutLMR">
            <LayoutLMR pageContext={pageContext} frontmatter={frontmatter} isDesktop={isDesktop} >
              {<MDXRenderer>{mdxBody}</MDXRenderer>}
            </LayoutLMR>
          </Container>
          )}

        {isMainDown && (
          <Container component={"section"} maxWidth="xl" style={styleBase} id="mainDown" key="mainDown">
            <LazyDynamicComponent chunkName={`${contentDir}-MainDown`} relPath={`${contentDir}/${comps.MainDown}`} />
          </Container>
        )}

        {isFullDown && (

          <Container
            component={"section"}
            maxWidth={false}
            id="fullDown"
            className="fullD"
            style={styleBase}
            key="cont-fullDown">
            <LazyDynamicComponent chunkName={`${contentDir}-FullDown`} relPath={`${contentDir}/${comps.FullDown}`} />
          </Container>
        )}

        {isFullDown2 && (
          <Container
            component={"section"}
            maxWidth={false}
            id="fullDown2"
            className="fullD2"
            key="cont-fullDown2">
            <LazyDynamicComponent chunkName={`${contentDir}-FullDown2`} relPath={`${contentDir}/${comps.FullDown2}`} />
          </Container>
        )}
        {isMobileDown && (!isDesktop) && (
          <Container
            component={"section"}
            style={styleBase} id="mobileDown" className="MobileDown" key="mobileDown">
            <LazyDynamicComponent chunkName={`${contentDir}-MobileDown`} relPath={`${contentDir}/${comps.MobileDown}`} />
          </Container>
        )}
        {isMobileDown2 && (!isDesktop) && (
          <Container component={"section"} style={styleBase} id="MobileDown2" className="MobileDown2" key="mobileDown2">
            <LazyDynamicComponent chunkName={`${contentDir}-MobileDown2`} relPath={`${contentDir}/${comps.MobileDown2}`} />
          </Container>
        )}

        {/* <Container
              component={"section"}
              maxWidth="lg"
              className="templContFoot"
            >
              <Suspense fallback="...loading">
                <LazyFooter />
              </Suspense>
            </Container> */}
        {/* </FrameBgForDiv>
        </FrameMaskForDiv> */}
      </Container>
      {/* <ScrollTop >
        <Fab size="small" aria-label="scroll back to top">
          <KeyboardArrowUpIcon />
        </Fab>
      </ScrollTop> */}

    </Fragment>
  );
};


export default PageTempl;

