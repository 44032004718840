import { useStaticQuery, graphql } from "gatsby";
//  //  @type
//         @type:

const useSiteMetadata = () => {
  const { site } = useStaticQuery(
    graphql`
      query SiteMetaData {
        site {
          siteMetadata {
            siteUrl
            siteTitle
            siteDescription
            siteImage
            siteKeywords
            author
            email
            phone
            logoSrc
            faviconLogoSrc
            siteLang
            nameCookieGdpr
            siteImgsRelDirInStatic

          }
        }
      }
    `
  );

  return site.siteMetadata;
};

export default useSiteMetadata
// providerSiteStructuredData
