import React, { useState, useEffect, useContext } from 'react';
import CookieConsent, { Cookies } from "react-cookie-consent";
import { useLocation } from "@reach/router"
import { initializeAndTrack } from 'gatsby-plugin-gdpr-cookies'
import { motion, useAnimation } from 'framer-motion'

import Button from '@mui/material/Button';
import Badge from '@mui/material/Badge';
import styled from '@mui/material/styles/styled';

import { ContextGdprCookieConsentDispatch } from './ContextGdprCookieConsent';


const isBrowser = typeof window !== "undefined"

const StyledMotionDivWrapConsent = styled(motion.div)(({ theme }) => ({
  position: "fixed",
  display: "flex",
  alignItems: "center",
  flexDirection: "column",
  width: "100%",
  zIndex: 10000,
  opacity: 0,
  ".styleButtonWrap": {
    display: "flex",
    justifyContent: "center",
    backgroundColor: theme.palette.secondary.main,
    marginTop: "10px",
  },
  ".button": {
    background: `linear-gradient(${theme.palette.primary.main},${theme.palette.primary2.main},${theme.palette.secondary.main},${theme.palette.secondary2.main})`,
    color: theme.palette.primary2.contrastText,
    // fontWeight: "bolder",
    textShadow: "2px 2px black",
    borderRadius: "4px",
    height: "50px",
    marginLeft: "5px",
    marginRight: "5px",
    fontSize: "small",
  },
  ".declineButton": {
    background: `linear-gradient(${theme.palette.primary2.main},${theme.palette.primary.main},${theme.palette.secondary2.main},${theme.palette.secondary.main})`,
    color: theme.palette.primary.contrastText,
    borderRadius: "4px",
    height: "50px",
    marginLeft: "5px",
    marginRight: "5px",
    fontSize: "small",

  },
  ".overlay": {

  },
  ".container": {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    backgroundColor: theme.palette.primary2.main,
    // background: `linear-gradient(${theme.palette.primary.main},${theme.palette.primary2.main},${theme.palette.secondary.main},${theme.palette.secondary2.main})`,
    margin: "0 20px",
    padding: "20px 0",
    boxShadow: theme.shadows[ 15 ],
    borderTopRightRadius: "10px",
    borderTopLeftRadius: "10px",
  },
  ".content": {
    color: theme.palette.primary2.contrastText,
    paddingLeft: "30px",
    paddingRight: "30px",
    textAlign: "center",

    "& .MuiBadge-root": {
      marginRight: "20px"
    }
  },
}))


const varWrapConsent = {
  initial: false,
  enter: {
    x: 0,
    opacity: 1,
    transition: {
      duration: 0.5,
    }
  },
  exit: {
    x: -100,
    opacity: 0,
    transitionEnd: { display: "none" },
    transition: {
      duration: 0.4,
    }
  },
}


/** CookieGdprConsentPopup
 * consuming ContextGdprCookieConsent about current GDPR cookie in browser
 * rendered only if there is no GDPR cookie
 * Animated question to allow GDPR cookie for Google Tag Manager.
 * Answer saved "gatsby-gdpr-google-tagmanager" true or false among browser Cookies
 * Automatic "accept" after countdown expiration
 * @param  {} {nameCookieGdpr
 * @param  {} heightHeader=40
 * @param  {} countdown=10}
 */
const CookieGdprConsentPopup = ({ nameCookieGdpr, heightHeader = 40, countdown = 10 }) => {
  const location = useLocation();
  const topPosition = heightHeader + 1

  const { acceptCookie, declineCookie } = useContext(ContextGdprCookieConsentDispatch)

  const [ secs, setSeconds ] = useState(countdown);


  const controls = useAnimation()
  const handleAcceptCookie = React.useCallback(async () => {
    initializeAndTrack(location)
    await controls.start("exit")
    acceptCookie()
  }, [ location, acceptCookie, controls ])

  const tick = () => { setSeconds((prev) => prev - 1) }

  useEffect(() => {
    if (isBrowser) {
      const timerId = window.setInterval(() => tick(), 1000);
      return () => clearInterval(timerId);
    }
  });


  useEffect(() => {
    if (isBrowser) {
      let timer1
      const asyncAnimateCookieQuestion = async () => {
        await controls.start("enter")

        timer1 = window.setTimeout(async () => {
          Cookies.set(nameCookieGdpr, true, { expires: 30 })
          await handleAcceptCookie()
        }, countdown * 1000)
      }

      asyncAnimateCookieQuestion().then(() => { })
      return () => {
        controls.stop()
        clearTimeout(timer1);
      }
    }
  }, [ countdown, handleAcceptCookie, nameCookieGdpr, controls ]);


  const handleDeclineCookie = async () => {
    controls.stop()
    await controls.start("exit")
    Cookies.set(nameCookieGdpr, 'false', { expires: 7 });
    declineCookie()
  };

  return (
    <StyledMotionDivWrapConsent
      variants={varWrapConsent}
      initial="initial"
      animate={controls}
      layout
      style={{
        // top: topPosition, 
        bottom: 0,
        x: -100,
        fontSize: "small",
      }}
    >
      <CookieConsent
        acceptOnScroll={true}
        acceptOnScrollPercentage={50}
        disableStyles={true}
        ButtonComponent={({ children, ...props }) => <Button {...props} variant="contained"> {children}</Button>}
        overlayClasses="overlay"
        buttonClasses="button"
        declineButtonClasses="declineButton MuiButtonBase-root MuiButton-root"
        buttonWrapperClasses="styleButtonWrap"
        containerClasses="container"
        contentClasses="content"
        overlay={true}
        id="#consentModal"
        // location="none"
        location="bottom"
        // buttonText={` 😊 Súhlas ${secs}`}
        // declineButtonText="😕 Nepovoliť"
        buttonText={`Súhlasím ${secs}`}
        declineButtonText="Nesúhlasím"
        cookieName={nameCookieGdpr}
        expires={150}
        enableDeclineButton
        flipButtons={true}
        style={{}}
        buttonStyle={{}}
        declineButtonStyle={{}}
        hideOnAccept={false}
        hideOnDecline={false}
        onAccept={handleAcceptCookie}
        onDecline={handleDeclineCookie}
      >
        <div>
          <Badge badgeContent={secs} color="primary" anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }} />
          Web7.sk robí minimálne záznamy o návštevách a záznam preferencií (farebný režim) v cookie súbore u návštevníka. Zaznamenávanie môžte odmietnuť.</div>
      </CookieConsent>

    </StyledMotionDivWrapConsent>
  )
};

export default CookieGdprConsentPopup;