import React from 'react';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import LazyDynamicComponent from './LazyDynamicComponent';

import { COMPONENTS_FILENAMES_IN_LMR as comps } from "./mapComponents"

const styleMain = {
  flexDirection: "column",
  width: "100%",
  justifyContent: "flex-start",
  position: "relative",
};

const styleLeft = {
  display: [ "none", "flex" ],
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "flex-start",
};


const styleRight = {
  display: [ "none", "flex" ],
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "flex-start",
};


/** LayoutLMR
 * called conditionally from Page template via shouldRenderMdx in frontmatter in mdx for page
 * if true sectionLMR in frontmatter determines if only middle part with children = mdx content - or middle and conditionally left, right columns as well - LeftC,  RightC, if exist "_leftC.js", "_rightC.js"  in components/pagesContents/${contentDir}
 * above <MainCUp> and under <MainCDown> sections are possibly rendered - depends if there are ""_mainCUp.js"" and "_mainCDown.js" in components/pagesContents/${contentDir}
 * @param  {} {children
 * @param  {} isDesktop
 * @param  {} frontmatter
 * @param  {} pageContext}
 */
const LayoutLMR = ({ children, isDesktop, frontmatter, pageContext }) => {
  const { contentDir = "notFound" } = pageContext
  const {
    sectionsLMR,
    sectionLMR = "M",
  } = frontmatter;

  let isMainCUp = sectionsLMR?.includes("MainCUp")
  const isMainCDown = sectionsLMR?.includes("MainCDown")
  const isPageNotFound = (contentDir === "notFound") ? true : false
  if (isPageNotFound) isMainCUp = false

  const layoutType = sectionLMR.toUpperCase()

  return (

    <div
      // id="layoutLMR"
      style={{
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
        minHeight: "100vh",
      }}>

      <Container
        id="LayoutLMRCont"
        maxWidth={false}
      >
        {layoutType === "LMR" && (
          <Grid
            container
            spacing={2}
          >
            <Grid
              xs={12} sm={2} md lg={3}
              key="LgridLMR"
            >
              <aside style={styleLeft} key="leftFlex-LMR">
                {isDesktop &&
                  <LazyDynamicComponent chunkName={`${contentDir}-LeftC`} relPath={`${contentDir}/${comps.LeftC}`} isQuick={true} />

                }
              </aside>
            </Grid>
            <Grid
              xs={12} sm={8} md={3} lg={6}
              key="MgridLMR"
            >
              <main
                style={styleMain}
              >
                {isMainCUp &&
                  <LazyDynamicComponent chunkName={`${contentDir}-MainCUp`} relPath={`${contentDir}/${comps.MainCUp}`} isQuick={true} />
                }
                {children}
                {isMainCDown &&
                  <LazyDynamicComponent chunkName={`${contentDir}-MainCDown`} relPath={`${contentDir}/${comps.MainCDown}`} isQuick={true} />
                }
              </main>
            </Grid>
            <Grid
              xs={12} sm={2} md lg={3}
              key="RgridLMR"
            >
              <aside style={styleRight} key="rightFlex-LMR">
                {isDesktop &&
                  <LazyDynamicComponent chunkName={`${contentDir}-RightC`} relPath={`${contentDir}/${comps.RightC}`} isQuick={true} />
                }
              </aside>
            </Grid>
          </Grid>
        )}

        {layoutType === "MR" && (
          <Grid
            container
            spacing={2}
          >
            <Grid
              xs={12} sm={8} md={3} lg={8}
              key="MgridMR"
            >
              <main
                style={styleMain}
                key="mainFlex-MR"
              >
                {isMainCUp &&
                  <LazyDynamicComponent chunkName={`${contentDir}-MainCUp`} relPath={`${contentDir}/${comps.MainCUp}`} isQuick={true} />
                }
                {children}
                {isMainCDown &&
                  <LazyDynamicComponent chunkName={`${contentDir}-MainCDown`} relPath={`${contentDir}/${comps.MainCDown}`} isQuick={true} />
                }
              </main>
            </Grid>
            <Grid
              xs={12} sm={4} md lg={4}
              key="RgridMR"
            >
              <aside style={styleRight}              >
                {isDesktop &&
                  <LazyDynamicComponent chunkName={`${contentDir}-RightC`} relPath={`${contentDir}/${comps.RightC}`} isQuick={true} />
                }
              </aside>

            </Grid>
          </Grid>
        )}


        {layoutType === "LM" && (
          <Grid
            container
            spacing={2}
          >
            <Grid
              xs={12} sm={4} md lg={4}
              key="LgridLM"
            >
              <aside style={styleLeft} key="leftFlex-LM">
                {isDesktop &&
                  <LazyDynamicComponent chunkName={`${contentDir}-LeftC`} relPath={`${contentDir}/${comps.LeftC}`} isQuick={true} />
                }
              </aside>
            </Grid>
            <Grid
              xs={12} sm={8} md={3} lg={8} key="MgridLM"
            >
              <main
                style={styleMain}
                key="mainFlex-LM"
              >
                {isMainCUp &&
                  <LazyDynamicComponent chunkName={`${contentDir}-MainCUp`} relPath={`${contentDir}/${comps.MainCUp}`} isQuick={true} />
                }
                {children}
                {isMainCDown &&
                  <LazyDynamicComponent chunkName={`${contentDir}-MainCDown`} relPath={`${contentDir}/${comps.MainCDown}`} isQuick={true} />
                }
              </main>

            </Grid>
          </Grid>
        )}

        {layoutType === "M" && (
          <main
            style={styleMain}
          >
            {isMainCUp &&
              <LazyDynamicComponent chunkName={`${contentDir}-MainCUp`} relPath={`${contentDir}/${comps.MainCUp}`} isQuick={true} />
            }
            {children}
            {isMainCDown &&
              <LazyDynamicComponent chunkName={`${contentDir}-MainCDown`} relPath={`${contentDir}/${comps.MainCDown}`} isQuick={true} />
            }
          </main>
        )}
      </Container>


    </div>
  );
};

export default LayoutLMR;
