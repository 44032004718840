import React, { Fragment } from 'react'
import Helmet from 'react-helmet'
import useSiteMetadata from "./useSiteMetadata"


function SEO(props) {
  const { path, seo, structuredDataFull } = props
  const { description, lang, keywords, title, image } = seo
  const siteMetaData = useSiteMetadata();

  const {
    siteTitle,
    siteImage,
    siteUrl,
    siteDescription,
    siteKeywords,
    siteLang,

  } = siteMetaData;

  if (seo) seo.canonicalPath = path;

  const mergedSeo = {
    url: path ? `${siteUrl}${path}` : siteUrl,
    description: description || siteDescription,
    title: title || siteTitle,
    keywords: keywords || siteKeywords,
    lang: lang || siteLang,
    image: image || siteImage,
  }
  return (
    <>
      <Helmet
        defer={true}
      >
        <title>{mergedSeo.title}</title>
        <html lang={mergedSeo.lang} />
        <link
          rel="canonical"
          href={`${mergedSeo.url}`}
        />
        <meta
          name="description"
          content={mergedSeo.description}
        />
        <meta name="image" content={mergedSeo.image} />
        <meta name="keywords" content={mergedSeo.keywords} />

        <meta name="docsearch:version" content="2.0" />
        <meta property="og:title" content={mergedSeo.title} />
        <meta property="og:url" content={mergedSeo.url} />
        <meta property="og:description" content={mergedSeo.description} />
        <meta property="og:type" content="website" />
        <meta property="og:locale" content={mergedSeo.lang} />
        <meta property="og:site_name" content={mergedSeo.title} />
        <meta property="og:image" content={mergedSeo.image} />
        <meta property="og:image:alt" content={mergedSeo.description} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={mergedSeo.title} />
        <meta name="twitter:url" content={mergedSeo.url} />
        <meta name="twitter:description" content={mergedSeo.description} />
        <meta name="twitter:image" content={mergedSeo.image} />
        <meta name="twitter:image:alt" content={mergedSeo.description} />
      </Helmet>
      {structuredDataFull && (
        <Helmet defer={true} >
          <script type="application/ld+json">
            {JSON.stringify(structuredDataFull)}
          </script>
        </Helmet>
      )}
    </>
  )
}

export default SEO
